import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When developing a conversational experience, the journey doesn’t end with deployment. The next challenge developers face is user acquisition and discovery. After all, there’s no physical interface to browse on a voice platform and discovery is a tough egg to crack. So what do you do? We asked a panel of experts to dive into their best practices for user acquisition and here’s what they had to say.`}</p>
    <h2>{`Great content attracts users`}</h2>
    <p>{`It doesn’t matter which category a conversational experience fits in; great content is universally necessary to attract users and keep them coming back. Something of a precautionary step, designing content that people are actively searching for alleviates some of the struggle developers feel as they try to acquire users. According to `}<a parentName="p" {...{
        "href": "https://xappmedia.com/"
      }}>{`XAPPmedia`}</a>{` VP of Business Development Kirk Owen, you should know your own brand and your customers. Think about the questions they’d want to ask and you’d be able to answer in a way that’s trusted by consumers.`}</p>
    <p>{`“At Amazon, they take the customer and work backwards,” said Owen, who previously worked with third-party developers on the Alexa team. “See how you can delight them and what moves the needle for them and put the technology in place to make that happen.”`}</p>
    <p>{`Going a step further, `}<a parentName="p" {...{
        "href": "https://invokedapps.com/"
      }}>{`Invoked Apps`}</a>{` founder Nick Schwab recommends a strategic approach to naming voice skills.`}</p>
    <p>{`“I built apps like ‘`}<a parentName="p" {...{
        "href": "https://www.amazon.com/Invoked-Apps-LLC-Ambient-Sounds/dp/B01LXQXW3G/ref=lp_17480337011_1_5?s=digital-skills&ie=UTF8&qid=1559086411&sr=1-5"
      }}>{`Rain Sounds`}</a>{`’ and ‘`}<a parentName="p" {...{
        "href": "https://www.amazon.com/Invoked-Apps-LLC-Ambient-Sounds/dp/B01M3RCM3B/ref=lp_17480337011_1_3?s=digital-skills&ie=UTF8&qid=1559086411&sr=1-3"
      }}>{`Thunderstorm Sounds`}</a>{`’, phrases that people are going to naturally search for,” he said.`}</p>
    <p>{`It’s an approach that has taken Scwab far. He’s the author of some of the most reviewed Alexa Skills. They’re some of the most highly rated, too, with an average of 4.9 stars across his various skills. Schwab also reaches over 2 million users each month through his range of ambient noise skills.`}</p>
    <h2>{`Work with the platforms`}</h2>
    <p>{`A lot of voice skills struggle with discoverability. Amazon, for example, will often default to first-party functionality instead of directing a user’s query to a third-party, branded skill. At another `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/04/26/superbot-2019-future-of-voice/"
      }}>{`SuperBot panel`}</a>{`, Mark Mezrich, VP of Product Management, Emerging Products at `}<a parentName="p" {...{
        "href": "https://www.viacom.com/"
      }}>{`Viacom`}</a>{`, encourages developers to work with the platforms to ensure their products delivering content in a meaningful way. Scwab gave similar advice, citing the importance of using the platform’s nativabilities to your advantage. For example, the Google Assistant’s `}<a parentName="p" {...{
        "href": "https://developers.google.com/actions/discovery/implicit"
      }}>{`implicit invocation`}</a>{` feature—set up properly—will take a user to your skill without requiring explicit invocation.`}</p>
    <p>{`Owen also sees the challenge here but approaches it differently as someone who creates interactive audio advertising for other brands. He advises brands to be more aggressive with training users to ask for the branded experience specifically. They don’t have the option of being generic with their titles and their voice apps often fulfill specific functions, so explicitly is key for user acquisition.`}</p>
    <h2>{`Systems Win`}</h2>
    <p><a parentName="p" {...{
        "href": "https://rain.agency/"
      }}>{`RAIN`}</a>{` Chief Creative Officer Will Hall said it best: `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/04/08/superbot-2019-presentations/"
      }}>{`systems win`}</a>{`. It’s a sentiment echoed by `}<a parentName="p" {...{
        "href": "https://www.snaps.io/"
      }}>{`Snaps`}</a>{` CEO Christian Brucculeri. According to him, while these companies are competing with each other, as an agency, he’s competing with other communication channels within each company for resources. The first big hurdle he has to face is persuading companies to devote those resources and include this new channel in their existing systems.`}</p>
    <p>{`“We see the most success when we get our partners to integrate into their customer journey,” he said. “When our partners spend enough time, they tend to do fairly well.”`}</p>
    <p>{`It makes sense. Users aren’t going to organically stumble onto your voice experience if it lives separately from the existing customer journey. By integrating into the system, you can guide users to where you want them. Stephen Keefer, Sr. Software Developer at `}<a parentName="p" {...{
        "href": "https://www.accuweather.com/"
      }}>{`AccuWeather`}</a>{`, integrates into other aspects of a user’s life to aid in discovery as well. Weather affects everything, so there are plenty of ways that Keefer’s been able to work the AccuWeather voice persona into existing systems besides the flagship app.`}</p>
    <h2>{`Iterate, iterate, iterate`}</h2>
    <p>{`Retention is the second behemoth after acquisition. After all, onboarding all these new users means next to nothing if you can’t get them to come back. All our panelists expressed the importance of tracking data and constantly improving upon the experience. Set-it-and-forget-it is a surefire way to failure.`}</p>
    <p>{`“You do as much as you can early on, but recognize that putting your best foot forward is just a first step,” said Owen. “`}{`[`}{`A voice experience`}{`]`}{` is going to need a `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour"
      }}>{`data feed`}</a>{`, more resources, and time in order to optimize and reduce friction. Sometimes you even have to start over again if you missed it.”`}</p>
    <p>{`Schwab ensures that he gives his users a way to contact him either through the experience or by leaving his email as a way to collect feedback, which he takes into serious consideration as a way to improve his voice apps. Even if there aren’t resources to analyze feedback at scale, he recommends using an `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`analytics tool`}</a>{` or reading the reviews on Amazon and Google to get a more granular look into your voice apps. According to Keefer, any kind of comments he gets back from users is helpful when he’s iterating on the AccuWeather skill.`}</p>
    <p>{`Constantly improving upon your voice experience ensures that whatever frustrating kinks in the system don’t stay there for long. It becomes a more satisfying experience and satisfied users are more likely to recommend your voice skill to their network. User acquisition will come more naturally after that.`}</p>
    <h2>{`Watch the full panel here`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/GkrY0UotAC4?feature=oembed" width="1200"></iframe>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+user+acquisition+panel"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      